@import "~jsoneditor/dist/jsoneditor.min.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import '~@angular/cdk/overlay-prebuilt.css';

@import url("../node_modules/@fortawesome/fontawesome-free/css/all.css");
@import url("../node_modules/primeng/resources/themes/nova-light/theme.css");
@import url("../node_modules/primeng/resources/primeng.min.css");
@import url("../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css");
@import '~primeicons/primeicons.css';

/* You can add global styles to this file, and also import other style files */




:root.theme-light {
 
  ---background: #fff;
  ---background-primary: #0daaba;
  ---background-primary-hover: #097682;
  ---background-overlay: #aaa;
  ---background-primary-light: #e6f6f8;
  ---background-disabled: #eaeaea;
  ---background-navbar: #000000;
  ---background-light: #eaeaea;
  ---background-picklist-caption: #f4f4f4;

  ---background-dashboard: #e6f6f8;
  ---background-dashboard-chart-title: lightgrey;
  ---text-dashboard-chart-title: #222;
  
  ---background-analysis-menu: #e6f6f8;
  ---background-execution-menu: #e6f6f8;
  ---background-matching-menu: #e6f6f8;
  ---background-workflow-menu: #e6f6f8;
  ---background-topics: #e6f6f8;
  ---background-selection-list: #e6f6f8;

  ---card-border: #eaeaff;
  ---text: #222;
  ---text-active: #fff;
  ---text-primary: #0daaba;
  ---text-light: #bbbbbb;
  ---text-highlight: #333333;
  ---text-disabled: #6c757d;
  ---text-placeholder: #6c757d;
  ---text-dark: #222;
  --text-picklist-caption: #333333;

  /*---border: #6c757d; */
  ---border: #c8c8c8;
  ---border-primary: #0daaba;
  ---border-primary-light: #e6f6f8;
  ---border-contextmenu: #eaeaea;
  ---border-light: #eaeaea;

  ---btn-background-active: #097682;
  ---btn-background-hover: #097682;
  ---btn-text: #fff;
  ---btn-border-active: #097682;
  ---btn-border-hover: #eaeaea;


  ---grid-text: #222;
  ---grid-btn-global-text: #222;
  ---grid-row-background-color-primary: #fcfefe;
  ---grid-row-background-color-secondary: #f2fafb;

  ---dashboard-text: #222;

  ---textarea-background: #eaeaea;
  ---scatterplot-background: #e6f6f8;

  ---danger: #e91224;
  ---danger-hover: #c01120;
  ---valid: #34A835;

  ---truestatus-normal: #41BB8A;
  ---truestatus-anomaly: #F55B5B;
  ---truestatus-followup: #B0C8DA;
  ---truestatus-assign: #7D9EB8;
  ---truestatus-unknown: #4682b4;

  ---gauss-simple-anomaly: lightsalmon;

  ---matching-test: #0daaba;
  ---matching-all: #B0C8DA;
  ---matching-train: #34A835;
  ---matching-request: green;
  ---matching-exception:  #e91224;
  ---matching-rule:brown;
  ---matching-reconciled: #34A835;
  ---matching-notreconciled: #222;

  ---matchable: mediumseagreen;
  ---one-manual: orange;
  ---reco-manual: chocolate;
  ---only-manual: red;
  ---in-manual: chocolate;
  ---many-manual: #222;
  ---text-many-manual: #fff;

  ---kmode: #fff;
}

:root.theme-dark {
  ---background: #222;
  ---background-primary: #0daaba;
  ---background-primary-hover: #097682;
  ---background-overlay: #aaa;
  ---background-primary-light: #222;
  ---background-disabled: #6c757d;
  ---background-navbar: #000000;
  ---background-light: #eaeaea;
  ---background-picklist-caption: #222;

  ---background-dashboard: #222;
  ---background-dashboard-chart-title: lightgrey;
  ---text-dashboard-chart-title: #222;

  ---background-analysis-menu: #222;
  ---background-execution-menu: #222;
  ---background-matching-menu: #222;
  ---background-workflow-menu: #222;
  ---background-topics: #222;
  ---background-selection-list: #222;

  ---card-border: #eaeaff;
  ---text: #ddd;
  ---text-primary: #0daaba;
  ---text-active: #fff;
  ---text-light: #bbbbbb;
  ---text-highlight: #fff;
  ---text-disabled: #aaa;
  ---text-placeholder: #aaa;
  ---text-dark: #222;
  ---text-picklist-caption: #ddd;

  ---border: #ddd;
  ---border-primary: #0daaba;
  ---border-primary-light: #e6f6f8;
  ---border-contextmenu: #eaeaea;
  ---border-light: #eaeaea;


  ---btn-background-active: #097682;
  ---btn-background-hover: #097682;
  ---btn-text: #fff;
  ---btn-border-active: #097682;
  ---btn-border-hover: #eaeaea;


  ---grid-text: #0daaba;
  ---grid-btn-global-text: #222;
  ---grid-row-background-color-primary: #222;
  ---grid-row-background-color-secondary: #222;

  ---dashboard-text: #ddd;

  ---textarea-background: #6c757d;
  ---scatterplot-background: #6c757d;

  ---danger: #e91224;
  ---danger-hover: #c01120;
  ---valid: #34A835;

  ---truestatus-normal: #41BB8A;
  ---truestatus-anomaly: #F55B5B;
  ---truestatus-followup: #B0C8DA;
  ---truestatus-assign: #7D9EB8;
  ---truestatus-unknown: #4682b4;

  ---gauss-simple-anomaly: lightsalmon;

  ---matching-test: #0daaba;
  ---matching-train: #34A835;
  ---matching-request: green;
  ---matching-exception:  #e91224;
  ---matching-rule:brown;
  ---matching-reconciled: #34A835;
  ---matching-notreconciled: #6c757d;

  ---matchable: mediumseagreen;
  ---one-manual: orange;
  ---reco-manual: chocolate;
  ---only-manual: red;
  ---in-manual: chocolate;
  ---many-manual: #222;
  ---text-many-manual: #fff;

  ---kmode: #fff;
}


body {
  overflow-x: hidden;
}

.btn-primary,
.btn-primary:focus {
  color: var(---btn-text);
  background-color: var(---background-primary);
  border-color: var(---border-primary);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn-primary:disabled {
  color: var(---btn-text);
  background-color: var(---btn-background-active);
  border-color: var(---btn-border-active);
  ;
}

.background-navbar {
  background-color: var(---background-navbar) !important;
}

.leftText {
  text-align: left;
}

.rightText {
  text-align: right;
}

.centerText {
  text-align: center;
}

.center {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.nopadding {
  padding: 0;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-6 {
  padding-left: 6px !important;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-top-18 {
  padding-top: 18px !important;
}

.closeButton {
  cursor: pointer;
  z-index: 1000;
}


.toolTip,
.toolTip2 {
  position: absolute;
  display: none;
  min-width: 80px;
  height: auto;
  text-align: center;
  line-height: 1;
  font: 14px sans-serif;
  padding: 12px;
  border-radius: 2px;
  z-index: 20000;
}

.toolTip th {
  color: var(---text);
  background-color:var(---background-primary);
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
}

.toolTip td {
  color: var(---text);
  background-color: var(---background);
  height: 22px;
}

.td1 {

  border-top: 1px dashed var(---border-primary);
  border-bottom: 1px dashed var(---border-primary);
  border-left: 1px dashed var(---border-primary);
  padding-top: 1px;
  padding-left: 1px;
}

.td2 {

  border-top: 1px dashed var(---border-primary);
  border-bottom: 1px dashed var(---border-primary);
  padding-left: 3px;
  padding-right: 3px;
}

.tdPoint {
  color: var(---text-active) !important;
  background-color: var(---background-primary) !important;
  height: 22px;
}

.td3 {
  border: 1px dashed var(---border-primary);
  padding-left: 3px;
  padding-right: 3px;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v43/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* confirm dialog */
.ui-dialog .ui-dialog-footer {
  height: 50px;
}

/* confirm button*/
.ui-dialog-footer .ui-button {
  float: right;
}

/*cancel button*/
.ui-dialog-footer .ui-button+.ui-button {
  background: var(---background-overlay);
  float: left;
  color: var(---text-dark);
  border-color: var(---border);
}

.ui-confirmdialog.ui-dialog .ui-dialog-content {
  height: 70px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.centerDiv {
  margin: auto;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.font-12 {
  font-size: 12px;
}

.margin-left-2 {
  margin-left: 2px;
}

.margin-right-0 {
  margin-right: 0px;
}


.margin-right-2 {
  margin-right: 2px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-right-40 {
  margin-right: 40px;
}

.over-auto {
  overflow-x: auto;
  min-width: 50px;
}
.ui-tooltip {
  width: 500px !important;
  height: auto;
  display: inline-block;
}

.ui-tooltip .ui-tooltip-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginRight10 {
  margin-right: 10px;
}

.textAlignRight {
  text-align: right
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.ui-picklist .ui-picklist-droppoint {
  height: 0px;
  list-style-type: none;
}

.ui-picklist .ui-picklist-list li {
  margin: 0px !important;
  /* padding: .125em;*/
  padding: 0 !important;
}

.applyButton {
  position: absolute;
  right: 160px;
  border-radius: 3.2px;
}

.applyButtonChain {
  margin-left: 2px;
  padding-left: 5x;
  padding-right: 5px;
  position: absolute;
  right: 80px;
  border-radius: 3.2px;
}

app-charts {
  width: 100%;
}



.textAlignCenter {
  text-align: center;
}

.ui-picklist-source-controls {
  display: none !important;
}

.ui-picklist-target-controls {
  display: none !important;
}


.piHelp {
  float: right;
  font-size: 2em !important;
  cursor: pointer;
}

.sectionTitle {
  font-weight: normal;
  text-align: center;
}



.dynamic {
  font-size: 13px;
  font-style: italic;
}

/* disable hover toolbar for plotly */
.modebar {
  display: none;
}

.margin-bottom-3 {
  margin-bottom: 3px;
}

.label-vertical {
  writing-mode: vertical-rl;
  text-orientation: upright;
}

#workflowDropdown,
#dashboardSourceDropdown {
  width: 100%;
}



.inline-flex {
  display: inline-flex;
}

.applyButtonGroup {
  float: right;
}

.applyButtonGroup button {
  margin-right: 5px !important;
  margin-left: 5px !important;
  border-radius: 0.3rem !important;
}

.input-group-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(---text-light);
  background-color: var(---background);
  background-clip: padding-box;
  border: 1px solid var(---border);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

cdk-virtual-scroll-viewport {
  min-height: 100%;
}

.dropdownBtnGroup {
  border: 0;
  border-radius: 0;
}


/* make sure all dropdowns are at the front */
.cdk-overlay-pane,
.cdk-overlay-container {
  z-index: 20000 !important;
}

/* CARDABEL LIST BOX */

.cardabel-listbox-checkbox {
  height: 29px;
  display: inline-flex;
  width: auto;
}

.cardabel-listbox-checkbox span {
  vertical-align: middle;
  padding-left: 15px;
  padding-top: 1px;
  display: inline-flex;
  min-width: 100%;
  contain: content;
  white-space: nowrap;
}

.cardabel-listbox-checkbox .mat-checkbox-frame {
  height: 20px !important;
  width: 20px !important;
}

.cardabel-listbox-checkbox mat-checkbox {
  padding-left: 10px;
  margin-top: 2px;
  /* position: absolute; */
}

.cardabel-listbox-checkbox .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}

.cardabel-filter-menu-mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 12px) !important;
  top: calc(50% - 12px) !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 50%;
}

#currencyViewport .cdk-virtual-scroll-content-wrapper {
  width: 100%;
  contain: none;
}




.reconciled .mat-checkbox-background,
.reconciled.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(---matching-reconciled) !important;
}

.reconciled.virtual-table-column {
  color: var(---valid) !important;
  font-weight: bold;
}

.notreconciled .mat-checkbox-background,
.notreconciled.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(---matching-notreconciled) !important;
}

.notreconciled.virtual-table-column {
  font-weight: bold;
}

.ongoingModification .mat-checkbox-background,
.ongoingModification.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(---danger) !important;
}

.ongoingModification.virtual-table-column {
  color: var(---danger) !important;
  font-weight: bold;
}




.mat-pseudo-checkbox {
  height: 20px !important;
  width: 20px !important;
  border: 1px solid var(---border) !important;
}

.mat-pseudo-checkbox-checked::after {
  top: 5.4px !important;
  left: 3px !important;
  width: 10px !important;
}

.mat-pseudo-checkbox-checked {
  height: 20px !important;
  width: 20px !important;
  border-color: white !important;
}

.dropup,
.dropdown {
  z-index: 20000 !important;
}

/*nav css*/
.navigationSettings,
.moveColumns,
.scrollColumns,
.toggleColumns,
.saveBookmark {
  z-index: 20000 !important;
}

.navigationSettings .ui-dropdown {
  width: 177px;
}

.navigationSettings .trash {
  color: var(---danger);
}

.navigationSettings .plus {
  color: var(---text);
}
.navigationSettings .ui-dialog-content {
  height: 500px;
  overflow-x: hidden !important;
}

.moveColumns .ui-dialog-content,
.scrollColumns .ui-dialog-content,
.toggleColumns .ui-dialog-content {
  /* min-width: 700px!important; */
  height: 452px;
  overflow-x: hidden !important;
}

.filterSignedColumns .ui-dialog-content {
  min-width: 400px !important;
  height: 50px;
  overflow-x: hidden !important;
}

.saveBookmark .ui-dialog-content {
  height: 350px;
  min-width: 350px;
  overflow-x: hidden !important;
}

.btn-cancel,
.btn-confirm {
  margin-top: -3px !important;
}

.btn-cancel {
  color: var(---text);
  border-color: var(---border);
  background: var(---background);

}


#navigationSettings .cardabelSelectionList {
  height: 150px;
}

.cardabelSelectionList cdk-virtual-scroll-viewport {
  height: 300px;

}

.dropdown-sm .cardabelSelectionList {
  height: 150px;
}

.dropdown-sm form,
.dropdown-md form,
.dropdown-lg form,
.dropdown-sm .cardabelSelectionList,
.dropdown-md .cardabelSelectionList,
.dropdown-lg .cardabelSelectionList {
  max-width: 300px;
}

.dropdown-sm cdk-virtual-scroll-viewport {
  height: 140px;
}

.dropdown-md cdk-virtual-scroll-viewport {
  height: 240px;
}

.dropdown-lg cdk-virtual-scroll-viewport {
  height: 340px;
}

.dropdown-md .cardabelSelectionList {
  height: 250px;
}

.dropdown-lg .cardabelSelectionList {
  height: 350px;
}

.dropdown-width-170 {
  width: 170% !important;
}

button {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.mat-paginator-container {
  min-height: 25px !important;
  height: 25px !important;
  padding: 0 !important;
}

.mat-paginator .mat-form-field-infix {
  padding: 0;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-size: 10px !important;
}

.mat-paginator-range-actions button {
  height: 35px;
  width: 35px;
}

.mat-paginator-container,
.virtual-table-bottom {
  color: var(---text);
  background: var(---background);
}

.ui-confirmdialog.ui-dialog .ui-dialog-content {
  height: auto;
}

.ui-confirmdialog .errorConfirm {
  color: red;
  opacity: 0.8;
}

.ui-picklist .ui-button {
  margin-bottom: 0 !important;
}

body .ui-button.ui-button-text-only .ui-button-text {
  padding: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: center;
}



.btn-basic {
  color: var(---text-light);
  background-color: var(---background-light);
}

.btn-basic:hover,
.btn-basic:focus {
  color: var(---btn-text);
  background-color: var(---background-primary-hover);
}

.ui-picklist-buttons .ui-button {
  margin-bottom: 0.25em !important;
}

.ui-sidebar {
  height: 100vh;
}

p-confirmdialog .ui-button.ui-button-text-only .ui-button-text {
  padding: 0.429em 1em !important;
}

.ui-dialog .pi-window-maximize {
  display: none !important;
}

.ui-toast-detail {
  overflow-wrap: break-word;
}



.cardabelSelectionList cdk-virtual-scroll-viewport::ng-deep {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.cardabelSelectionList .cdk-virtual-scroll-content-wrapper {
  contain: none;
}

/* .cardabelSelectionList .cdk-virtual-scroll-content-wrapper {
  overflow-x:auto;
  overflow-y: hidden;
} */


.menu-filters-item {
  min-height: 48px !important;
  white-space: unset !important;
  overflow: unset !important;
  white-space: nowrap !important;
  max-width: unset !important;
  width: fit-content !important;
}


.grid-column-menu {
  max-width: fit-content !important;
}

.dropdownAbove.dropdown,
.dropdownAbove.dropup,
.dropdownAbove.dropup mat-header {
  z-index: 30000 !important;
}

.dropdownAbove1.dropdown,
.dropdownAbove1.dropup,
.dropdownAbove1.dropup mat-header {
  z-index: 30001 !important;
}

.suspens {
  border: 3px solid var(---border-primary) !important;
}

.reco_manual {
  border: 3px solid var(---reco-manual) !important;
}

.manual_only {
  border: 3px solid var(---only-manual) !important;
}

.card_one_manual {
  background-color: var(---one-manual) !important;
}

.one_manual {
  border: 3px solid var(---one-manual) !important;
}

.many_manual {
  border: 3px solid var(---many-manual) !important;
}

.card_many_manual {
  background-color: var(---many-manual) !important;
  color: var(---text-many-manual) !important;
}

.card_in_manual {
  background-color: var(---in-manual) !important;
}

.suspens_only {
  background-color: var(---background-primary) !important;
}

.matchable {
  border: 3px solid var(---matchable) !important;
}

.card_matchable {
  background-color: var(---matchable) !important;
}


.cardabel-selection-filters-menu-scroller {
  height: 150px;
}



.cdk-drag-dragging,
.cdk-drag-preview {
  z-index: 30000 !important;
}

body .ui-selectbutton .ui-button.ui-state-active {
  color: var(---text-active) !important;
  background-color: var(---background-primary) !important;
  border-color: var(---border-primary) !important;
}

.kernel {
  background-color: var(---background-primary-light);
  border: 3px solid;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 15px;
}

body .ui-progressbar .ui-progressbar-value {
  background: var(---valid) !important;
}

.mat-button-focus-overlay,
.mat-button-ripple.mat-ripple {
  display: none !important;
}

.cardabel-selection-list-header .fa-check {

  color: var(---text-primary);

}

.cardabel-selection-list-container,
.cardabel-selection-list-container .btn-light {

  background-color: var(---background-primary-light);


}

.cardabel-selection-list-container .btn-light:hover,
.cardabel-selection-list-container .btn-light:focus,
.cardabel-selection-list-container .btn-light:active,
.cardabel-selection-list-container .btn-light:not(:disabled):not(.disabled).active,
.cardabel-selection-list-container .btn-light:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: var(---btn-text);
  background-color: var(---background-primary);
  border-color: var(---border-primary);
  box-shadow: unset !important;
}

body .ui-picklist .ui-picklist-caption {
  background-color: var(---background-picklist-caption)!important;
  color: var(---text-picklist-caption)!important;
}
body .ui-picklist .ui-picklist-list, body .ui-picklist .ui-picklist-filter-container, body .ui-picklist .ui-picklist-list .ui-picklist-item {
  background-color: var(---background) !important;
  color: var(---text)!important;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
  background-color: var(---background-primary)!important;
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  color: var(---text)!important;
}

p-confirmdialog .ui-button.ui-button-text-only .ui-button-text {
  color: #ffffff!important;
}